import React from 'react';
import { Upload } from 'lucide-react';

interface FileUploadFieldProps {
  name: string;
  label: string;
  isPicture?: boolean;
  value?: string;
  onChange: (value: string) => void;
  error?: string;
}

const FileUploadField = ({ 
  name, 
  label, 
  isPicture,
  value,
  onChange,
  error 
}: FileUploadFieldProps) => {
  const [fileName, setFileName] = React.useState<string>('');
  const [preview, setPreview] = React.useState<string>(value || '');
  
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setFileName(file.name);
      const reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result as string;
        onChange(result);
        setPreview(result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="flex flex-col gap-1 w-full">
      <label className="text-sm font-medium text-gray-700 mb-2">
        {label}
      </label>
      <div className="relative">
        <input
          type="file"
          id={`file-${name}`}
          accept="image/*"
          className="hidden"
          onChange={handleFileChange}
        />
        <label
          htmlFor={`file-${name}`}
          className={`flex items-center px-4 py-2 w-full border rounded-lg cursor-pointer transition-colors duration-200
            ${error ? 'border-red-500' : ''}
          `}
        >
          <div className="flex items-center gap-2 w-full min-h-[2.5rem]">
            <Upload className="w-4 h-4 text-gray-500 flex-shrink-0" />
            <span className="text-sm text-gray-600 truncate">
              {fileName || 'Choose file'}
            </span>
          </div>
          {preview && (
            <div className="absolute right-2 top-1/2 -translate-y-1/2">
              <img 
                src={preview} 
                alt="Preview" 
                className="w-8 h-8 object-cover rounded"
              />
            </div>
          )}
        </label>
      </div>
      {error && (
        <span className="text-xs text-red-500 mt-1">{error}</span>
      )}
    </div>
  );
};

export default FileUploadField;