import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "@/hooks/use-toast";
import { updateProfessional } from "../services/update-professional";
import { CreateProfessionalInput } from "../services/create-professional";


export function useUpdateProfessional() {
    const queryClient = useQueryClient();
  
    return useMutation({
      mutationFn: ({ id, data }: { id: string; data: CreateProfessionalInput }) =>
        updateProfessional(id, data),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["professionals"] });
        toast({ title: "Professional updated successfully" });
      },
      onError: (error) => {
        console.error("Error updating professional:", error);
        toast({ title: "Failed to update professional", variant: "destructive" });
      },
    });
  }
