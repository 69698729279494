import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createBundle } from "../services/create-bundle";
import { CreateBundleRequest } from "@/pages/bundles/types/bundle";

export function useCreateBundle() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: CreateBundleRequest) => createBundle(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["bundles"] });
    },
  });
}
