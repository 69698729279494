import axiosInstance from "@/api/axios-instance";

export async function undoNoShow(source_id: string) {
  const response = await axiosInstance.delete(`/sessions/no_show/${source_id}`);

  if (!response.status.toString().startsWith("2")) {
    throw new Error("Failed to Undo Mark No Show");
  }

  return response.data;
}
