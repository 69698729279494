import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "@/hooks/use-toast";
import { CouponFormData } from "../schema/coupon-schema";
import { updateCoupon } from "../services/update-coupon";

export function useUpdateCoupon() {
    const queryClient = useQueryClient();
    
    return useMutation({
      mutationFn: ({ id, data }: { id: string; data: Partial<CouponFormData> }) => 
        updateCoupon(id, data),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["coupons"] });
        toast({ title: "Success", description: "Coupon updated successfully" });
      },
      onError: (error) => {
        toast({ title: "Error", description: error.message || "Failed to update coupon", variant: "destructive" });
      },
    });
  }