import { SignedIn, useClerk, useSession } from "@clerk/clerk-react";
import AppRouter from "./components/common/app-router";
import { Toaster } from "./components/ui/toaster";

export default function App() {
  const { redirectToSignIn } = useClerk();
  const { isLoaded, isSignedIn } = useSession();

  if (!isLoaded) {
    return null;
  }
  if (!isSignedIn) {
    redirectToSignIn().then((x) => x);
    return null;
  }

  // Redirect to the sign-in page if the user is not signed in
  return (
    <header className="flex items-center justify-center py-8 px-4">
      <SignedIn>
        <AppRouter />
        <Toaster />
      </SignedIn>
    </header>
  );
}
