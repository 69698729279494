import { z } from "zod";

export const ProfessionalType = {
  COUNSELING_PSYCHOLOGIST: 'COUNSELING_PSYCHOLOGIST',
  REGISTERED_CLINICAL_PSYCHOLOGIST: 'REGISTERED_CLINICAL_PSYCHOLOGIST',
  REGISTERED_CLINICAL_PSYCHOLOGIST_ASSOCIATE: 'REGISTERED_CLINICAL_PSYCHOLOGIST_ASSOCIATE',
  PSYCHIATRIST_MD: 'PSYCHIATRIST_MD',
  PSYCHIATRIST_DNB: 'PSYCHIATRIST_DNB',
  OBGYN_MS: 'OBGYN_MS',
  SURGEON_MS: 'SURGEON_MS',
  GENERAL_PHYSICIAN: 'GENERAL_PHYSICIAN'
} as const;

export type ProfessionalType = typeof ProfessionalType[keyof typeof ProfessionalType];

export const ProfessionalStatus = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  DISMISSED_TAKING_OLD_PATIENT: 'DISMISSED_TAKING_OLD_PATIENT',
  FULLY_DISMISSED: 'FULLY_DISMISSED',
  TEMPORARY_INACTIVE: 'TEMPORARY_INACTIVE'
} as const;

export type ProfessionalStatus = typeof ProfessionalStatus[keyof typeof ProfessionalStatus];

export const JobCommitment = {
    PART_TIME: 'PART_TIME',
    FULL_TIME: 'FULL_TIME',
    FREELANCER: 'FREELANCER'
  } as const;
  

  export type JobCommitment = typeof JobCommitment[keyof typeof JobCommitment];

// helper object for human-readable enum labels
export const professionalTypeLabels: Record<ProfessionalType, string> = {
  [ProfessionalType.COUNSELING_PSYCHOLOGIST]: "Counseling Psychologist",
  [ProfessionalType.REGISTERED_CLINICAL_PSYCHOLOGIST]: "Registered Clinical Psychologist",
  [ProfessionalType.REGISTERED_CLINICAL_PSYCHOLOGIST_ASSOCIATE]: "Registered Clinical Psychologist Associate",
  [ProfessionalType.PSYCHIATRIST_MD]: "Psychiatrist MD",
  [ProfessionalType.PSYCHIATRIST_DNB]: "Psychiatrist DNB",
  [ProfessionalType.OBGYN_MS]: "OBGYN MS",
  [ProfessionalType.SURGEON_MS]: "Surgeon MS",
  [ProfessionalType.GENERAL_PHYSICIAN]: "General Physician"
};

export const professionalStatusLabels: Record<ProfessionalStatus, string> = {
  [ProfessionalStatus.ACTIVE]: "Active",
  [ProfessionalStatus.INACTIVE]: "Inactive",
  [ProfessionalStatus.DISMISSED_TAKING_OLD_PATIENT]: "Dismissed (Taking Old Patients)",
  [ProfessionalStatus.FULLY_DISMISSED]: "Fully Dismissed",
  [ProfessionalStatus.TEMPORARY_INACTIVE]: "Temporarily Inactive"
};

export const jobCommitmentLabels: Record<JobCommitment, string> = {
    [JobCommitment.FULL_TIME]: "Full Time",
    [JobCommitment.PART_TIME]: "Part Time",
    [JobCommitment.FREELANCER]: "Freelancer"
  };


  // phone regex patterrn (need to include country code +91)
const phoneRegex = /^\+?[1-9]\d{1,14}$/;

export const professionalSchema = z.object({
  name: z.string().min(1, "Name is required"),
  type: z.enum(Object.values(ProfessionalType) as [string, ...string[]]),
  sex: z.string().min(1, "Sex is required"),
  age: z.number().int().min(1).nullable(),
  email: z.string().email().nullable(),
  phone_number: z.string().regex(phoneRegex, "Invalid phone number format").nullable(),
  registration_number: z.string().nullable(),
  years_of_experience: z.number().int().min(0).max(100).nullable(),
  status: z.enum(Object.values(ProfessionalStatus) as [string, ...string[]]).nullable(),
  personal_email: z.string().email().nullable(),
  address: z.string().nullable(),
  area_of_expertise: z.string().nullable(),
  department: z.string().min(1, "Department is required"),
  languages: z.array(z.string()).min(1, "At least one language is required"),
  supervisor_id: z.string().nullable(),
  age_range_minor: z.array(z.number().int()).max(2).nullable(),
  age_range_adult: z.array(z.number().int()).max(2).nullable(),
  bio: z.string().nullable(),
  country: z.string().nullable(),
  has_kid: z.boolean().nullable(),
  is_lead: z.boolean().nullable(),
  is_married: z.boolean().nullable(),
  is_supervisor: z.boolean().nullable(),
  qualification: z.string().nullable(),
  specialization: z.string().nullable(),
  university_name: z.string().nullable(),
  preferred_notification_number: z.string().regex(phoneRegex, "Invalid phone number format").nullable(),
  pronouns: z.string().nullable(),
  picture: z.string().nullable(),
  signature: z.string().nullable(),
  stamp: z.string().nullable(),
  job_commitment: z.enum(Object.values(JobCommitment) as [string, ...string[]]).nullable()
});

export type ProfessionalFormData = z.infer<typeof professionalSchema>;
