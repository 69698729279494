import axiosInstance from "@/api/axios-instance";
import { ProfessionalType, ProfessionalStatus, JobCommitment } from "@/features/professionals/schema/professional-schema"

export interface CreateProfessionalInput {
    name: string;
    type: string; 
    sex: string;
    status: string;  
    job_commitment: string; 
    age: number | null;
    years_of_experience: number | null;
    email?: string | undefined;
    phone_number?: string | undefined;
    registration_number?: string | undefined;
    personal_email?: string | undefined;
    address?: string | undefined;
    area_of_expertise?: string | undefined;
    languages: string[];  
    department?: string | undefined;
    age_range_minor: number[]; 
    age_range_adult: number[]; 
    bio?: string | undefined;
    country?: string | undefined;
    has_kid: boolean; 
    is_lead: boolean;  
    is_married: boolean;  
    is_supervisor: boolean;
    qualification?: string | undefined;
    specialization?: string | undefined;
    university_name?: string | undefined;
    preferred_notification_number?: string | undefined;
    pronouns?: string | undefined;
    supervisor_id?: string | undefined;
  }

export async function createProfessional(data: CreateProfessionalInput) {
  const apiData = {
    ...data,
    status: data.status || ProfessionalStatus.ACTIVE,
    type: data.type || ProfessionalType.COUNSELING_PSYCHOLOGIST,
    job_commitment: data.job_commitment || JobCommitment.FULL_TIME,

    // required fields default values
    age_range_minor: data.age_range_minor || [0, 18],
    age_range_adult: data.age_range_adult || [19, 100],
  };

  const response = await axiosInstance.post("/professional", apiData);

  if (!response.status.toString().startsWith("2")) {
    throw new Error("Failed to create professional");
  }

  return response.data;
}
