import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createNoShow } from "../services/create-no-show";
import { useToast } from "@/hooks/use-toast";

export function useCreateNoShow() {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useMutation({
    mutationFn: ({ source_id }: { source_id: string }) =>
      createNoShow(source_id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["sessions"], exact: false });
      toast({
        title: "Success",
        description: "Marked No Show",
      });
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: error.message || "Failed to Mark No Show",
        variant: "destructive",
      });
    },
  });
}
