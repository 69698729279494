import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Switch } from "@/components/ui/switch";
import { useEffect, useState } from "react";
import { toast } from "@/hooks/use-toast";
import { Bundle } from "@/pages/bundles/types/bundle";
import { useCreateBundle } from "../hooks/use-create-bundle";
import { useUpdateBundle } from "../hooks/use-update-bundle";
import { useUpdateBundleStatus } from "../hooks/use-update-bundle-status";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useCoupons } from "../hooks/use-active-coupons";
import { Coupon } from "@/pages/coupons/types/coupon";
import { useForms } from "../hooks/use-active-forms";
import { Form as FormI } from "@/pages/forms/types/form";

export const bundleSchema = z
  .object({
    standard_id: z.string().min(1, "Standard ID is required"),
    name: z.string().min(1, "Bundle name is required"),
    amount: z.number().min(0, "Amount must be non-negative"),
    payment_page_link: z
      .string()
      .min(1, "Payment page link is required")
      .url("Please enter a valid URL"),
    session_quantity: z.number().nullable(),
    is_expiration_in_days: z.boolean(),
    expires_in_day: z.number().nullable(),
    expires_in_month: z.number().nullable(),
    product_id: z.string().nullable(),
    company_id: z.string().nullable(),
    total_allowed_user: z.number().nullable(),
    form_ids: z.array(z.string()).default([]),
    coupon_ids: z.array(z.string()).default([]),
    status: z.enum(["ACTIVE", "INACTIVE"]),
  })
  .refine(
    (data) => {
      if (data.is_expiration_in_days) {
        return data.expires_in_day !== null && data.expires_in_month === null;
      } else {
        return data.expires_in_month !== null && data.expires_in_day === null;
      }
    },
    {
      message: "Please provide either days or months for expiration",
      path: ["expires_in_day"],
    }
  );

type BundleFormData = z.infer<typeof bundleSchema>;

interface BundleFormProps {
  initialData?: Bundle | null;
  onClose: () => void;
  editMode?: boolean;
}

export default function BundleForm({
  initialData,
  onClose,
  editMode,
}: BundleFormProps) {
  // const form = useForm<BundleFormData>({
  //   resolver: zodResolver(bundleSchema),
  //   defaultValues: initialData ?? {
  //     standard_id: "",
  //     name: "",
  //     amount: 0,
  //     payment_page_link: "",
  //     session_quantity: null,
  //     expires_in_day: null,
  //     expires_in_month: null,
  //     total_allowed_user: null,
  //     form_ids: [],
  //     coupon_ids: [],
  //     product_id: null,
  //     company_id: null,
  //     status: "INACTIVE",
  //     is_expiration_in_days: false,
  //   },
  // });
  const form = useForm<BundleFormData>({
    resolver: zodResolver(bundleSchema),
    defaultValues: {
      ...initialData,
      // Extract just the IDs from the coupons array if it exists
      coupon_ids: initialData?.coupons?.map((coupon) => coupon.id) || [],
      // Extract just the IDs from the forms array if it exists
      form_ids: initialData?.forms?.map((form) => form.id) || [],
      // Rest of the default values
      standard_id: initialData?.standard_id || "",
      name: initialData?.name || "",
      amount: initialData?.amount || 0,
      payment_page_link: initialData?.payment_page_link || "",
      session_quantity: initialData?.session_quantity || null,
      expires_in_day: initialData?.expires_in_day || null,
      expires_in_month: initialData?.expires_in_month || null,
      total_allowed_user: initialData?.total_allowed_user || null,
      product_id: initialData?.product_id || null,
      company_id: initialData?.company_id || null,
      status: initialData?.status || "INACTIVE",
      is_expiration_in_days: initialData?.is_expiration_in_days || false,
    },
  });

  const createBundleMutation = useCreateBundle();
  const updateBundleMutation = useUpdateBundle();
  const updateStatusMutation = useUpdateBundleStatus();
  const isSubmitting =
    createBundleMutation.isPending || updateBundleMutation.isPending;
  const [isExpirationInDays, setIsExpirationInDays] = useState<boolean>(
    initialData?.is_expiration_in_days ?? false
  );

  useEffect(() => {
    form.setValue("is_expiration_in_days", isExpirationInDays);
  }, [isExpirationInDays, form]);

  const onSubmit = async (data: BundleFormData) => {
    try {
      console.log("Form data being submitted:", data);
      if (editMode && initialData?.id) {
        await updateBundleMutation.mutateAsync({
          id: initialData.id,
          data: data,
        });
      } else {
        await createBundleMutation.mutateAsync(data);
      }
      toast({
        title: "Success",
        description: `Bundle ${editMode ? "updated" : "created"} successfully`,
      });
      onClose();
    } catch (error: any) {
      console.error("Form submission error:", error);
      toast({
        title: "Error",
        description: error.response?.data?.message || "Failed to save bundle",
        variant: "destructive",
      });
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6 p-6">
        {/* Header */}
        <div className="bg-gray-100 rounded-lg p-4">
          <h2 className="text-2xl font-semibold">
            {editMode ? "Edit" : "Add"} Bundle
          </h2>
        </div>

        {/* Basic Information */}
        <div className="space-y-4">
          <h3 className="text-lg font-medium">Basic Information</h3>
          <div className="grid grid-cols-2 gap-4">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    Bundle Name <span className="text-red-500">*</span>
                  </FormLabel>
                  <FormControl>
                    <Input placeholder="Bundle Name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="standard_id"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    Standard ID <span className="text-red-500">*</span>
                  </FormLabel>
                  <FormControl>
                    <Input placeholder="Standard ID" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="amount"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    Amount <span className="text-red-500">*</span>
                  </FormLabel>
                  <FormControl>
                    <Input
                      type="number"
                      placeholder="Amount"
                      {...field}
                      onChange={(e) => field.onChange(Number(e.target.value))}
                      value={field.value || ""}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="payment_page_link"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    Payment Page Link <span className="text-red-500">*</span>
                  </FormLabel>
                  <FormControl>
                    <Input placeholder="https://..." {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>

        {/* Session Details */}
        <div className="space-y-4">
          <h3 className="text-lg font-medium">Session Details</h3>
          <div className="grid grid-cols-2 gap-4">
            <FormField
              control={form.control}
              name="session_quantity"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Session Quantity</FormLabel>
                  <FormControl>
                    <Input
                      type="number"
                      placeholder="Number of sessions"
                      {...field}
                      onChange={(e) =>
                        field.onChange(
                          e.target.value ? Number(e.target.value) : null
                        )
                      }
                      value={field.value ?? ""}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="total_allowed_user"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Total Allowed Users</FormLabel>
                  <FormControl>
                    <Input
                      type="number"
                      placeholder="Maximum users allowed"
                      {...field}
                      onChange={(e) =>
                        field.onChange(
                          e.target.value ? Number(e.target.value) : null
                        )
                      }
                      value={field.value ?? ""}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>

        {/* Expiration Settings */}
        <div className="space-y-4">
          <h3 className="text-lg font-medium">Expiration Settings</h3>
          <FormField
            control={form.control}
            name="is_expiration_in_days"
            render={({ field }) => (
              <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                <div className="space-y-0.5">
                  <FormLabel className="text-base">
                    Expiration in Days
                  </FormLabel>
                </div>
                <FormControl>
                  <Switch
                    checked={isExpirationInDays}
                    onCheckedChange={(checked) => {
                      setIsExpirationInDays(checked);
                      field.onChange(checked);
                      if (checked) {
                        form.setValue("expires_in_month", null);
                      } else {
                        form.setValue("expires_in_day", null);
                      }
                    }}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <div className="grid grid-cols-2 gap-4">
            <FormField
              control={form.control}
              name="expires_in_day"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Expires in Days</FormLabel>
                  <FormControl>
                    <Input
                      type="number"
                      placeholder="Number of days"
                      disabled={!isExpirationInDays}
                      {...field}
                      onChange={(e) =>
                        field.onChange(
                          e.target.value ? Number(e.target.value) : null
                        )
                      }
                      value={field.value ?? ""}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="expires_in_month"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Expires in Months</FormLabel>
                  <FormControl>
                    <Input
                      type="number"
                      placeholder="Number of months"
                      disabled={isExpirationInDays}
                      {...field}
                      onChange={(e) =>
                        field.onChange(
                          e.target.value ? Number(e.target.value) : null
                        )
                      }
                      value={field.value ?? ""}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>

        {/* Relationships */}
        <div className="space-y-4">
          <h3 className="text-lg font-medium">Relationships</h3>
          <div className="grid grid-cols-2 gap-4">
            <FormField
              control={form.control}
              name="product_id"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Product ID</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Product ID"
                      {...field}
                      value={field.value ?? ""}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="company_id"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Company ID</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Company ID"
                      {...field}
                      value={field.value ?? ""}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>

        {/* Status */}
        <div className="space-y-4">
          <h3 className="text-lg font-medium">Status</h3>
          <FormField
            control={form.control}
            name="status"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Bundle Status</FormLabel>
                <Select
                  value={field.value}
                  onValueChange={(value: "ACTIVE" | "INACTIVE") => {
                    console.log("Status changing to:", value); // Add this line
                    field.onChange(value);
                  }}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select status">
                        {field.value}
                      </SelectValue>
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="ACTIVE">Active</SelectItem>
                    <SelectItem value="INACTIVE">Inactive</SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        {/* associated items  */}
        <div className="space-y-4">
          <h3 className="text-lg font-medium">Associated Items</h3>
          <div className="grid grid-cols-2 gap-4">
            {/* Forms Multi-select */}
            <FormField
              control={form.control}
              name="form_ids"
              render={({ field }) => {
                const { data: formsData } = useForms();
                const forms = formsData?.forms || [];

                return (
                  <FormItem>
                    <FormLabel>Forms</FormLabel>
                    <FormControl>
                      <div className="space-y-2">
                        <Select
                          onValueChange={(value) => {
                            const currentValues = field.value || [];
                            if (!currentValues.includes(value)) {
                              field.onChange([...currentValues, value]);
                            }
                          }}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Select Forms" />
                          </SelectTrigger>
                          <SelectContent>
                            {forms.map((formItem: FormI) => (
                              <SelectItem key={formItem.id} value={formItem.id}>
                                {formItem.name}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>

                        <div className="flex flex-wrap gap-2">
                          {field.value?.map((selectedId) => {
                            const selectedForm = forms.find(
                              (f: FormI) => f.id === selectedId
                            );
                            return (
                              selectedForm && (
                                <div
                                  key={selectedId}
                                  className="flex items-center gap-2 bg-gray-100 px-3 py-1 rounded-full"
                                >
                                  <span className="text-sm">
                                    {selectedForm.name}
                                  </span>
                                  <button
                                    type="button"
                                    onClick={() => {
                                      field.onChange(
                                        field.value.filter(
                                          (id) => id !== selectedId
                                        )
                                      );
                                    }}
                                    className="text-red-500 hover:text-red-700"
                                  >
                                    ×
                                  </button>
                                </div>
                              )
                            );
                          })}
                        </div>
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />

            {/* Coupons Multi-select */}
            <FormField
              control={form.control}
              name="coupon_ids"
              render={({ field }) => {
                const { data: couponsData } = useCoupons();
                const coupons = couponsData?.coupons || [];

                return (
                  <FormItem>
                    <FormLabel>Coupons</FormLabel>
                    <FormControl>
                      <div className="space-y-2">
                        <Select
                          onValueChange={(value) => {
                            const currentValues = field.value || [];
                            if (!currentValues.includes(value)) {
                              field.onChange([...currentValues, value]);
                            }
                          }}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Select Coupons" />
                          </SelectTrigger>
                          <SelectContent>
                            {coupons.map((coupon: Coupon) => (
                              <SelectItem key={coupon.id} value={coupon.id}>
                                {coupon.name}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>

                        <div className="flex flex-wrap gap-2">
                          {field.value?.map((selectedId) => {
                            const selectedCoupon = coupons.find(
                              (c: Coupon) => c.id === selectedId
                            );
                            return (
                              selectedCoupon && (
                                <div
                                  key={selectedId}
                                  className="flex items-center gap-2 bg-gray-100 px-3 py-1 rounded-full"
                                >
                                  <span className="text-sm">
                                    {selectedCoupon.name}
                                  </span>
                                  <button
                                    type="button"
                                    onClick={() => {
                                      field.onChange(
                                        field.value.filter(
                                          (id) => id !== selectedId
                                        )
                                      );
                                    }}
                                    className="text-red-500 hover:text-red-700"
                                  >
                                    ×
                                  </button>
                                </div>
                              )
                            );
                          })}
                        </div>
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
          </div>
        </div>

        {/* Form Actions */}
        <div className="flex justify-end space-x-4 pt-6">
          <Button type="button" variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            {isSubmitting ? (
              <span>Loading...</span>
            ) : editMode ? (
              "Update"
            ) : (
              "Create"
            )}{" "}
            Bundle
          </Button>
        </div>
      </form>
    </Form>
  );
}
