import { useNavigate, useSearchParams } from "react-router-dom";
import ThreeDotsLoader from "@/components/common/three-dots-loader";

import { CalendarDays } from "lucide-react";
import { Card, CardContent } from "@/components/ui/card";
import useBundles from "@/features/bundles/hooks/useBundles";
import { useState } from "react";
import BundleCard from "@/features/bundles/ui/bundle-card";
import CustomPaginationComponent from "@/components/common/custom-pagination-component";
import VaulDrawer from "@/components/ui/drawer";
import AddEditBundlesForm from "@/features/bundles/ui/bundle-form";

export default function Bundles() {
  const [searchParams] = useSearchParams();
  const initialPage = parseInt(searchParams.get("page") || "0", 10);
  const [_page, setCurrentPage] = useState(initialPage);
  const navigate = useNavigate();
  const { isFetching, isLoading, isSuccess, data, error } = useBundles(_page);

  const [showBundleFormDrawer, setShowBundleFormDrawer] = useState(false);
  const [selectedBundle, setSelectedBundle] = useState<any>(null);

  if (error) {
    return <></>;
  }

  if (isFetching || isLoading || !data) {
    return <ThreeDotsLoader />;
  }

  const { bundles, lastPage: lastSkip, currentPage } = data;
  const lastPage = lastSkip + 1;

  const handlePageChange = (page: number) => {
    if (page >= 0 && page < lastPage) {
      setCurrentPage(page);
      navigate(`?page=${page}`, { replace: true });
    }
  };

  return (
    <div className="space-y-6 pt-6 px-6">
      {isSuccess && (
        <BundleCard
          bundles={bundles}
          setShowBundleDrawer={setShowBundleFormDrawer}
          setSelectedBundle={setSelectedBundle}
        />
      )}
      {bundles.length === 0 && (
        <Card>
          <CardContent className="flex flex-col items-center justify-center py-8">
            <CalendarDays className="h-8 w-8 text-muted-foreground" />
            <p className="mt-2 text-center text-sm text-muted-foreground">
              No bundles found
            </p>
          </CardContent>
        </Card>
      )}

      {bundles?.length ? (
        <CustomPaginationComponent
          currentPage={currentPage}
          lastPage={lastPage}
          onPageChange={handlePageChange}
        />
      ) : (
        <></>
      )}

      <VaulDrawer
        drawerDirection="right"
        isOpen={showBundleFormDrawer}
        onOpenChange={setShowBundleFormDrawer}
        triggerChildren={null}
        contentChildren={
          <AddEditBundlesForm
            editMode={selectedBundle ? true : false}
            onClose={() => setShowBundleFormDrawer(false)}
            initialData={selectedBundle}
          />
        }
      />
    </div>
  );
}
