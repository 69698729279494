import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createProfessional, CreateProfessionalInput } from "../services/create-professional";
import { toast } from "@/hooks/use-toast";

export function useCreateProfessional() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: CreateProfessionalInput) => createProfessional(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["professionals"] });
      toast({ title: "Professional created successfully" });
    },
    onError: (error) => {
      console.error("Error creating professional:", error);
      toast({ title: "Failed to create professional", variant: "destructive" });
    },
  });
}
