import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@/hooks/use-toast";
import { cancelSession } from "../services/cancel-session";

export function useCancelSession() {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useMutation({
    mutationFn: ({
      source_id,
      reason,
    }: {
      source_id: string;
      reason?: string;
    }) => cancelSession(source_id, reason),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["sessions"], exact: false });
      toast({
        title: "Success",
        description: "Session Cancelled Successfully",
      });
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: error.message || "Failed to Cancel Session",
        variant: "destructive",
      });
    },
  });
}
