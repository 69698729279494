import axiosInstance from "@/api/axios-instance";
import { UpdateBundleRequest } from "@/pages/bundles/types/bundle";

export async function updateBundle(id: string, data: UpdateBundleRequest) {
  const response = await axiosInstance.patch(`/bundle/${id}`, data);

  if (!response.status.toString().startsWith("2")) {
    throw new Error("Failed to update bundle");
  }

  return response.data;
}
