import { format } from "date-fns";
import { IndianRupee, Calendar } from "lucide-react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Coupon } from "@/pages/coupons/types/coupon";
import { Badge } from "@/components/ui/badge";
import { ScrollArea } from "@radix-ui/react-scroll-area";
import { Button } from "@/components/ui/button";

interface CouponCardProps {
  coupons: Coupon[];
  setSelectedCoupon: (value: any) => void;
  setShowCouponDrawer: (value: boolean) => void;
}

export function CouponCard({
  coupons,
  setSelectedCoupon,
  setShowCouponDrawer,
}: CouponCardProps) {
  const getDiscountText = (coupon: Coupon) => {
    if (coupon.discount_percentage) {
      return `${coupon.discount_percentage}% off`;
    }
    if (coupon.discount_amount) {
      return `${coupon.discount_amount} off`;
    }
    return "Special discount";
  };

  const getStatusVariant = (status: string) => {
    switch (status.toLowerCase()) {
      case "active":
        return "active";
      case "expired":
        return "destructive";
      case "inactive":
        return "secondary";
      default:
        return "default";
    }
  };

  return (
    <div className="space-y-6 md:w-[65vw] lg:w-[68vw] xl:w-[75vw] 2xl:w-[80vw]">
      <Card className="overflow-hidden">
        <CardHeader>
          <div className="flex items-center justify-between">
            <div>
              <CardTitle className="text-3xl">Coupons</CardTitle>
              <CardDescription className="text-sm">All Coupons</CardDescription>
            </div>

            <Button
              className="bg-sidebar"
              onClick={() => {
                setSelectedCoupon(null);
                setShowCouponDrawer(true);
              }}
            >
              Add Coupon
            </Button>
          </div>
        </CardHeader>
        <ScrollArea className="h-[calc(100%-5rem)]">
          <CardContent className="space-y-10">
            {coupons.map((coupon) => (
              <Card
                key={coupon.id}
                className="cursor-pointer"
                onClick={() => {
                  setSelectedCoupon(coupon);
                  setShowCouponDrawer(true);
                }}
              >
                <CardHeader className="bg-gray-50">
                  <div className="flex items-center justify-between">
                    <div className="space-y-1">
                      <CardTitle className="text-lg font-semibold">
                        {coupon.name}
                      </CardTitle>
                      <div className="flex items-center gap-2 text-sm text-muted-foreground">
                        <Calendar className="h-4 w-4" />
                        <span>
                          {format(new Date(coupon.start_date), "PPP")}
                          {coupon.end_date && (
                            <> - {format(new Date(coupon.end_date), "PPP")}</>
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="flex justify-between ">
                      {coupon.first_time_user_only && (
                        <Badge variant="gifted" className="w-fit mr-2">
                          FIRST TIME USERS ONLY
                        </Badge>
                      )}
                      <Badge variant={getStatusVariant(coupon.status)}>
                        {coupon.status.toUpperCase()}
                      </Badge>
                    </div>
                  </div>
                </CardHeader>
                <CardContent className="pt-4">
                  <div className="grid gap-4">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-2">
                        <IndianRupee className="h-4 w-4 text-muted-foreground" />
                        <span className="font-semibold text-muted-foreground">
                          {getDiscountText(coupon)}
                        </span>
                      </div>
                      <div className="text-sm text-muted-foreground">
                        {coupon.type.replace("_", " ")}
                      </div>
                    </div>

                    {(coupon.discount_min ||
                      coupon.discount_max ||
                      coupon.discount_cap) && (
                      <div className="grid gap-2 text-sm">
                        {coupon.discount_min && (
                          <div className="flex justify-between">
                            <span className="text-muted-foreground">
                              Min. Order Amount
                            </span>
                            <span>₹{coupon.discount_min}</span>
                          </div>
                        )}
                        {coupon.discount_max && (
                          <div className="flex justify-between">
                            <span className="text-muted-foreground">
                              Max. Order Amount
                            </span>
                            <span>₹{coupon.discount_max}</span>
                          </div>
                        )}
                        {coupon.discount_cap && (
                          <div className="flex justify-between">
                            <span className="text-muted-foreground">
                              Max. Discount
                            </span>
                            <span>₹{coupon.discount_cap}</span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </CardContent>
              </Card>
            ))}
          </CardContent>
        </ScrollArea>
      </Card>
    </div>
  );
}
