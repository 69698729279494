"use client";

import React from "react";
import { Drawer } from "vaul";

type DrawerProps = {
    drawerDirection: "top" | "bottom" | "right" | "left";
    customClass?: string;
    triggerChildren: React.ReactNode;
    contentChildren: React.ReactNode;
    isOpen?: boolean;
    onOpenChange?: (open: boolean) => void;
};

export default function VaulDrawer({
    customClass,
    drawerDirection,
    triggerChildren,
    contentChildren,
    isOpen,
    onOpenChange,
}: DrawerProps) {
    return (
        <Drawer.Root
            direction={drawerDirection}
            open={isOpen}
            onOpenChange={onOpenChange}
        >
            <Drawer.Trigger asChild>{triggerChildren}</Drawer.Trigger>
            <Drawer.Portal>
                <Drawer.Overlay className="fixed inset-0 z-50 bg-black/20 backdrop-blur-[2px]" />
                <Drawer.Content
                    className={`fixed overflow-y-auto scroll-smooth bg-white outline-none ${
                        drawerDirection === "top" && "inset-x-0 top-0 h-fit"
                    } ${
                        drawerDirection === "right" &&
                        "inset-y-4 right-2 z-50 ml-2 overflow-hidden rounded-lg md:right-4 md:ml-0 md:w-[659px]"
                    } ${customClass}`}
                    role="dialog"
                    aria-modal="true"
                    aria-label="Details Drawer"
                >
                    <div className="bg-white p-4">{contentChildren}</div>
                </Drawer.Content>
            </Drawer.Portal>
        </Drawer.Root>
    );
}
