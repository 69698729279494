import { useQuery } from "@tanstack/react-query";
import { getSubmissions } from "../services/get-submissions";
import { Submission } from "@/pages/submissions/types/submission";

interface PaginatedSubmissions {
  submissions: Array<Submission>;
  lastPage: number;
  nextPage: number | null;
  currentPage: number;
  total: number;
}

export default function useSubmissions(page: number = 0, search?: string) {
  const { data, isLoading, error, isFetching, isSuccess } =
    useQuery<PaginatedSubmissions>({
      queryKey: [
        "submissions",
        {
          page,
          search,
        },
      ],
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5, // 5 minutes
      queryFn: () => getSubmissions({ page, search }),
    });

  return { data, isLoading, error, isFetching, isSuccess };
}
