import { useQuery } from "@tanstack/react-query";
import { getProfessionals } from "../services/get-professionals";
import { Professional } from "@/pages/professionals/types/professional";

interface PaginatedProfessinals {
  professionals: Array<Professional>;
  lastPage: number;
  nextPage: number | null;
  currentPage: number;
  total: number;
}

export default function useProfessionals(page: number = 0, search?: string) {
  const { data, isLoading, error, isFetching, isSuccess } =
    useQuery<PaginatedProfessinals>({
      queryKey: [
        "professionals",
        page,
        search,
      ],
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 30, // 30 minutes
      queryFn: () => getProfessionals({ page, search }),
    });

  return { data, isLoading, error, isFetching, isSuccess };
}
