import axiosInstance from "@/api/axios-instance";

export async function getActiveBundles() {
  const response =await axiosInstance.get("/bundle/active?limit=100");

  if (!response.status.toString().startsWith("2")) {
    throw new Error("Failed to fetch active bundles");
  }

  return response.data;
}