import React, { useState, KeyboardEvent, ChangeEvent } from 'react';
import { Input } from "@/components/ui/input";
import { X } from "lucide-react";

interface LanguageTagsInputProps {
  value: string[];
  onChange: (value: string[]) => void;
  className?: string;
}

const LanguageTagsInput: React.FC<LanguageTagsInputProps> = ({ 
  value = [], 
  onChange,
  className = ''
}) => {
  const [inputValue, setInputValue] = useState<string>('');

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && inputValue.trim()) {
      e.preventDefault();
      if (!value.includes(inputValue.trim())) {
        onChange([...value, inputValue.trim()]);
      }
      setInputValue('');
    } else if (e.key === 'Backspace' && !inputValue && value.length > 0) {
      onChange(value.slice(0, -1));
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const removeTag = (tagToRemove: string) => {
    onChange(value.filter(tag => tag !== tagToRemove));
  };

  return (
    <div className={`w-full border rounded-lg p-2 py-2 flex items-center py-1 flex-wrap gap-2 bg-white focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2 ${className}`}>
      {value.map((tag, index) => (
        <span
          key={index}
          className="bg-gray-100 text-gray-800 px-3 py-1  rounded-full text-[13px] flex items-center gap-x-1 group"
        >
          {tag}
          <button
            type="button"
            onClick={() => removeTag(tag)}
            className="hover:bg-gray-200 rounded-full p-0.5 transition-colors"
          >
            <X size={13} />
          </button>
        </span>
      ))}
      <Input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        className="flex-1 !border-none shadow-none !ring-0 !outline-none min-w-[120px] h-8 p-0"
        placeholder={value.length === 0 ? "Type and press Enter to add languages" : ""}
      />
    </div>
  );
};

export default LanguageTagsInput;
