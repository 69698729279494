import axiosInstance from "@/api/axios-instance";
import { CreateProfessionalInput } from "../services/create-professional";

export async function updateProfessional(id: string, data: CreateProfessionalInput) {
  const response = await axiosInstance.patch(`/professional/${id}`, data);
  if (!response.status.toString().startsWith("2")) {
    throw new Error("Failed to update professional");
  }
  return response.data;
}
