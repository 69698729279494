import axiosInstance from "@/api/axios-instance";
import { CouponFormData } from "../schema/coupon-schema";
import { Coupon } from "@/pages/coupons/types/coupon";

export async function updateCoupon(id: string, data: Partial<CouponFormData>) {
    const response = await axiosInstance.patch<Coupon>(`/coupon/${id}`, data);
    
    if (!response.status.toString().startsWith("2")) {
      throw new Error("Failed to update coupon");
    }
    
    return response.data;
  }