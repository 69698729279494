import axiosInstance from "@/api/axios-instance";

export async function createNoShow(source_id: string) {
  const response = await axiosInstance.post(`/sessions/no_show`, { source_id });

  if (!response.status.toString().startsWith("2")) {
    throw new Error("Failed creating no show");
  }

  return response.data;
}
