import axiosInstance from "@/api/axios-instance";
import { Coupon } from "@/pages/coupons/types/coupon";

export async function updateCouponStatus(id: string, status: string) {
    const response = await axiosInstance.patch<Coupon>(`/coupon/status/${id}`, { status });
    
    if (!response.status.toString().startsWith("2")) {
      throw new Error("Failed to update coupon status");
    }
    
    return response.data;
  }