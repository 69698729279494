import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "@/hooks/use-toast";
import { updateCouponStatus } from "../services/update-coupon-status";

export function useUpdateCouponStatus() {
    const queryClient = useQueryClient();
    
    return useMutation({
      mutationFn: ({ id, status }: { id: string; status: string }) => 
        updateCouponStatus(id, status),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["coupons"] });
        toast({ title: "Success", description: "Coupon status updated successfully" });
      },
      onError: (error) => {
        toast({ title: "Error", description: error.message || "Failed to update coupon status", variant: "destructive" });
      }
    });
}