import { format } from "date-fns";
import { MoreHorizontal } from "lucide-react";

import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Badge } from "@/components/ui/badge";
import { Session } from "@/pages/sessions/types/session";
import CancelByInfo from "@/features/users/ui/canceled-by-info";
import { Link } from "react-router-dom";
import { useCreateNoShow } from "../hooks/use-create-no-show";
import { useUndoNoShow } from "../hooks/use-undo-no-show";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useState } from "react";
import { Textarea } from "@/components/ui/textarea";
import { useCancelSession } from "../hooks/use-cancel-session";

interface SessionCardProps {
  session: Session;
  onReschedule?: (id: string) => void;
}

export function SessionCard({ session, onReschedule }: SessionCardProps) {
  const {
    name,
    professional,
    status,
    start_time,
    end_time,
    invitee_email,
    invitee_full_name,
    is_no_show,
    is_rescheduled,
    invitee_phone,
    canceled_by,
    cancel_reason,
    source_id,
  } = session;

  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [cancelReason, setCancelReason] = useState<string | undefined>(
    undefined
  );
  const { mutate: cancelSession } = useCancelSession();

  const { mutate: createNoShow } = useCreateNoShow();
  const { mutate: undoNoShow } = useUndoNoShow();

  return (
    <Card className="w-full">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2 bg-secondary-background">
        <div>
          <Link to={`/sessions/${session.id}`}>
            <CardTitle className="text-xl font-bold text-sidebar-primary">
              {name}
            </CardTitle>
          </Link>
          <p className="text-sm text-muted-foreground">
            with {professional?.name}
          </p>
        </div>
        <div>
          <Badge variant={status === "active" ? "active" : "destructive"}>
            {status.toUpperCase()}
          </Badge>
          {is_no_show && (
            <Badge variant="completed" className="ml-1">
              NO SHOW
            </Badge>
          )}
          {is_rescheduled && (
            <Badge variant="gifted" className="ml-1">
              RESCHEDULED
            </Badge>
          )}
        </div>
      </CardHeader>
      <CardContent className="bg-secondary-background">
        <div className="flex justify-between items-center">
          <div className="space-y-1">
            <p className="text-sm font-medium">
              {format(new Date(start_time), "EEEE, MMMM d, yyyy")}
            </p>
            <p className="text-sm text-muted-foreground">
              {format(new Date(start_time), "h:mm a")} -{" "}
              {format(new Date(end_time), "h:mm a")}
            </p>
          </div>
          <div className="text-right">
            <p className="text-sm font-medium">{invitee_full_name}</p>
            <p className="text-sm text-muted-foreground"> {invitee_phone} </p>
            <p className="text-sm text-muted-foreground">{invitee_email}</p>
          </div>
        </div>
      </CardContent>
      {status === "canceled" && (
        <CancelByInfo cancelBy={canceled_by} cancelReason={cancel_reason} />
      )}

      <CardFooter className="justify-end">
        {status === "active" && (
          <>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" size="sm">
                  <MoreHorizontal className="h-4 w-4" />
                  <span className="sr-only">Actions</span>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem
                  onClick={async () => {
                    try {
                      await navigator.clipboard.writeText(
                        `https://rockethealth.app/reschedule/${source_id}`
                      );
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  Copy Reschedule Link
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => {
                    setCancelDialogOpen(true);
                  }}
                >
                  Cancel Session
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => onReschedule?.(session.id)}>
                  Reschedule
                </DropdownMenuItem>
                {(is_no_show === null || !is_no_show) && (
                  <DropdownMenuItem
                    onClick={() => {
                      createNoShow({ source_id: session.source_id });
                    }}
                  >
                    Mark No Show
                  </DropdownMenuItem>
                )}
                {is_no_show && (
                  <DropdownMenuItem
                    onClick={() => {
                      undoNoShow({ source_id: session.source_id });
                    }}
                  >
                    Undo No Show
                  </DropdownMenuItem>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
            <Dialog open={cancelDialogOpen} onOpenChange={setCancelDialogOpen}>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Cancel {invitee_full_name} Session</DialogTitle>
                </DialogHeader>
                <div className="py-4">
                  <Textarea
                    placeholder="Please provide a reason for cancellation"
                    value={cancelReason ?? ""}
                    onChange={(e) =>
                      setCancelReason(e.target.value || undefined)
                    }
                    className="min-h-[100px]"
                  />
                </div>
                <DialogFooter>
                  <Button
                    variant="outline"
                    onClick={() => setCancelDialogOpen(false)}
                  >
                    Close
                  </Button>
                  <Button
                    variant="destructive"
                    onClick={() => {
                      cancelSession({
                        source_id: session.source_id,
                        reason: cancelReason,
                      });
                      setCancelReason(undefined);
                      setCancelDialogOpen(false);
                    }}
                    disabled={cancelReason === undefined}
                  >
                    Confirm Cancellation
                  </Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          </>
        )}
      </CardFooter>
    </Card>
  );
}
