import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Ticket } from "lucide-react";
import { Card, CardContent } from "@/components/ui/card";
import ThreeDotsLoader from "@/components/common/three-dots-loader";
import CustomPaginationComponent from "@/components/common/custom-pagination-component";
import useCoupons from "@/features/coupons/hooks/use-coupons";
import { CouponCard } from "@/features/coupons/ui/coupon-card";
import VaulDrawer from "@/components/ui/drawer";
import AddEditCouponForm from "@/features/coupons/ui/coupon-form";
import { CouponData } from "@/features/coupons/schema/coupon-schema";

export default function Coupons() {
  const [searchParams] = useSearchParams();
  const [showCouponDrawer,setShowCouponDrawer]=useState(false);
  const [selectedCoupon,setSelectedCoupon]=useState<CouponData>()
  const initialPage = parseInt(searchParams.get("page") || "0", 10);
  const [_page, setCurrentPage] = useState(initialPage);
  const navigate = useNavigate();

  const { data, error, isFetching, isLoading, isSuccess } = useCoupons(_page);

  if (error) {
    return <></>;
  }

  if (isFetching || isLoading || !data) {
    return <ThreeDotsLoader />;
  }

  const { coupons, lastPage: lastSkip, currentPage } = data;
  const lastPage = lastSkip + 1;

  const handlePageChange = (page: number) => {
    if (page >= 0 && page < lastPage) {
      setCurrentPage(page);
      navigate(`?page=${page}`, { replace: true });
    }
  };

  return (
    <div className="flex-1 overflow-auto">
      <div className="h-full p-8">
        <div className="space-y-6">
          {isSuccess && coupons?.length > 0 ? (
            <>
              <CouponCard coupons={coupons} setSelectedCoupon={setSelectedCoupon} setShowCouponDrawer={setShowCouponDrawer} />
              <CustomPaginationComponent
                currentPage={currentPage}
                lastPage={lastPage}
                onPageChange={handlePageChange}
              />
            </>
          ) : (
            <Card>
              <CardContent className="flex flex-col items-center justify-center py-8">
                <Ticket className="h-8 w-8 text-muted-foreground" />
                <p className="mt-2 text-center text-sm text-muted-foreground">
                  No coupons found
                </p>
              </CardContent>
            </Card>
          )}
        </div>
      </div>

      <VaulDrawer
                drawerDirection="right"
                isOpen={showCouponDrawer}
                onOpenChange={setShowCouponDrawer}
                triggerChildren={null}
                contentChildren={
                    <AddEditCouponForm
                        editMode={selectedCoupon ? true:false}
                        onClose={() => setShowCouponDrawer(false)}
                        initialData={selectedCoupon}
                        setShowCouponDrawer={setShowCouponDrawer}
                        
                    />
                 }
            />
    </div>
  );
}
