import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateBundleStatus } from "../services/update-bundle-status";

export function useUpdateBundleStatus() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      id,
      status,
    }: {
      id: string;
      status: "ACTIVE" | "INACTIVE";
    }) => updateBundleStatus(id, status),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["bundles"] });
    },
  });
}
