import axiosInstance from "@/api/axios-instance";

export async function updateBundleStatus(
  id: string,
  status: "ACTIVE" | "INACTIVE"
) {
  const response = await axiosInstance.patch(`/bundle/status/${id}`, {
    status,
  });

  if (!response.status.toString().startsWith("2")) {
    throw new Error("Failed to update bundle status");
  }

  return response.data;
}
