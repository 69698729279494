import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Ticket } from "lucide-react";
import { Card, CardContent } from "@/components/ui/card";
import ThreeDotsLoader from "@/components/common/three-dots-loader";
import CustomPaginationComponent from "@/components/common/custom-pagination-component";
import useProfessionals from "@/features/professionals/hooks/use-professionals";
import { ProfessionalCard } from "@/features/professionals/ui/professional-card";
import VaulDrawer from "@/components/ui/drawer";
import AddEditProfessionalsForm from "@/features/professionals/ui/professional-form";

export default function Professionals() {
  const [searchParams] = useSearchParams();
  const [showProfessionalFormDrawer,setShowProfessionalFormDrawer]=useState(false);
  const [selectedProfessional,setSelectedProfessional]=useState<any>(null);
  const initialPage = parseInt(searchParams.get("page") || "0", 10);
  const [_page, setCurrentPage] = useState(initialPage);
  const navigate = useNavigate();

  const { data, error, isFetching, isLoading, isSuccess } =
    useProfessionals(_page);

  if (error) {
    return <></>;
  }

  if (isFetching || isLoading || !data) {
    return <ThreeDotsLoader />;
  }

  const { professionals, lastPage: lastSkip, currentPage } = data;
  const lastPage = lastSkip + 1;

  const handlePageChange = (page: number) => {
    if (page >= 0 && page < lastPage) {
      setCurrentPage(page);
      navigate(`?page=${page}`, { replace: true });
    }
  };

  return (
    <div className="flex-1 overflow-auto">
      <div className="h-full p-8">
        <div className="space-y-6">
          {isSuccess ? (
            <ProfessionalCard professionals={professionals} setShowProfessionalDrawer={setShowProfessionalFormDrawer}   setSelectedProfessional={setSelectedProfessional} />
          ) : (
            <Card>
              <CardContent className="flex flex-col items-center justify-center py-8">
                <Ticket className="h-8 w-8 text-muted-foreground" />
                <p className="mt-2 text-center text-sm text-muted-foreground">
                  No Professional found
                </p>
              </CardContent>
            </Card>
          )}
          {professionals?.length > 0 && (
            <CustomPaginationComponent
              currentPage={currentPage}
              lastPage={lastPage}
              onPageChange={handlePageChange}
            />
          )}
        </div>
      </div>


     <VaulDrawer
                drawerDirection="right"
                isOpen={showProfessionalFormDrawer}
                onOpenChange={setShowProfessionalFormDrawer}
                triggerChildren={null}
                contentChildren={
                    <AddEditProfessionalsForm
                        editMode={selectedProfessional ? true:false}
                        onClose={() => setShowProfessionalFormDrawer(false)}
                        initialData={selectedProfessional}
                    />
                 }
            />
    </div>
  );
}

