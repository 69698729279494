import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
  Briefcase,
  Mail,
  Phone,
  User,
  Calendar,
  Globe,
  UserPlus,
  Users,
  Star,
  Award,
} from "lucide-react";
import { Professional } from "@/pages/professionals/types/professional";
import { ScrollArea } from "@radix-ui/react-scroll-area";
import { Button } from "@/components/ui/button";

interface ProfessionalCardProps {
  professionals: Professional[];
  setShowProfessionalDrawer:(value:boolean)=>void;
  setSelectedProfessional:(value:any)=>void;
}

export function ProfessionalCard({ professionals,setShowProfessionalDrawer,setSelectedProfessional }: ProfessionalCardProps) {
  const titles = ["Dr.", "Mr.", "Ms.", "Mrs.", "Prof.", "Sir"];

  return (
    <div className="md:w-[65vw] lg:w-[68vw] xl:w-[75vw] 2xl:w-[80vw]">
      <Card className="overflow-hidden">
        <CardHeader >
          <div className="flex items-center justify-between">
          <div>
          <CardTitle className="text-3xl">Professionals</CardTitle>
          <CardDescription className="text-sm">
            All Professionals
          </CardDescription>
          </div>

          <Button className='bg-sidebar' onClick={()=>{
            setSelectedProfessional(null)
setShowProfessionalDrawer(true)

          }}>
            Add Professional
          </Button>
          </div>
        </CardHeader>
        <ScrollArea className="h-[calc(100%-5rem)]">
          <CardContent className="space-y-10">
            {professionals.map((professional) => (
              <Card key={professional.id} onClick={()=>{
                setSelectedProfessional(professional);
                setShowProfessionalDrawer(true);
              }}
              className="cursor-pointer"
              >
                <CardHeader className="flex flex-row items-center gap-4">
                  <Avatar className="h-16 w-16">
                    <AvatarImage
                      src={professional.picture || ""}
                      alt={professional.name}
                    />
                    <AvatarFallback>
                      {professional.name
                        .split(" ")
                        .filter((name) => !titles.includes(name))
                        .map((name) => name.charAt(0).toUpperCase())
                        .join("")}
                    </AvatarFallback>
                  </Avatar>
                  <div className="flex-1">
                    <CardTitle className="text-xl">
                      {professional.name}
                    </CardTitle>
                  </div>
                  <div className="flex gap-2 mt-1">
                    <Badge variant="outline">
                      {professional.job_commitment}
                    </Badge>
                    <Badge
                      variant={
                        professional.status === "ACTIVE"
                          ? "active"
                          : professional.status === "FULLY_DISMISSED"
                          ? "destructive"
                          : "secondary"
                      }
                    >
                      {professional.status}
                    </Badge>
                  </div>
                </CardHeader>
                <CardContent>
                  <div className="grid grid-cols-2 gap-4">
                    <div className="space-y-2">
                      <div className="flex items-center gap-2">
                        <Briefcase className="h-4 w-4 text-muted-foreground" />
                        <span className="text-sm">
                          {professional.department}
                        </span>
                      </div>
                      {professional.email && (
                        <div className="flex items-center gap-2">
                          <Mail className="h-4 w-4 text-muted-foreground" />
                          <span className="text-sm">{professional.email}</span>
                        </div>
                      )}
                      {professional.phone_number && (
                        <div className="flex items-center gap-2">
                          <Phone className="h-4 w-4 text-muted-foreground" />
                          <span className="text-sm">
                            {professional.phone_number}
                          </span>
                        </div>
                      )}
                      <div className="flex items-center gap-2">
                        <User className="h-4 w-4 text-muted-foreground" />
                        <span className="text-sm">
                          {professional.age
                            ? `${professional.age} years old`
                            : "Age not specified"}{" "}
                          • {professional.sex}
                        </span>
                      </div>
                      <div className="flex items-center gap-2">
                        <Calendar className="h-4 w-4 text-muted-foreground" />
                        <span className="text-sm">
                          Minor: {professional.age_range_minor.join("-")} •
                          Adult: {professional.age_range_adult.join("-")}
                        </span>
                      </div>
                    </div>
                    <div className="space-y-2">
                      {professional.languages && (
                        <div className="flex items-center gap-2">
                          <Globe className="h-4 w-4 text-muted-foreground" />
                          <span className="text-sm">
                            {professional.languages.join(", ")}
                          </span>
                        </div>
                      )}
                      {professional.supervisor && (
                        <div className="flex items-center gap-2">
                          <UserPlus className="h-4 w-4 text-muted-foreground" />
                          <span className="text-sm">
                            Supervisor: {professional.supervisor.name}
                          </span>
                        </div>
                      )}
                      {professional.leads && professional.leads.length > 0 && (
                        <div className="flex items-center gap-2">
                          <Users className="h-4 w-4 text-muted-foreground" />
                          <span className="text-sm">
                            Leads:{" "}
                            {professional.leads
                              .map((lead) => lead.name)
                              .join(", ")}
                          </span>
                        </div>
                      )}
                      <div className="flex items-center gap-2">
                        <Star className="h-4 w-4 text-muted-foreground" />
                        <span className="text-sm">
                          {professional.is_supervisor
                            ? "Supervisor"
                            : "Not a Supervisor"}
                        </span>
                      </div>
                      <div className="flex items-center gap-2">
                        <Award className="h-4 w-4 text-muted-foreground" />
                        <span className="text-sm">
                          {professional.is_lead ? "Lead" : "Not a Lead"}
                        </span>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            ))}
          </CardContent>
        </ScrollArea>
      </Card>
    </div>
  );
}

