import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createCoupon } from "../services/create-coupon";
import { toast } from "@/hooks/use-toast";
import { CouponFormData } from "../schema/coupon-schema";

export function useCreateCoupon() {
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: (data: CouponFormData) => createCoupon(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["coupons"] });
      toast({ title: "Success", description: "Coupon created successfully"});
    },
    onError: (error) => {
      toast({ title: "Error", description: error.message || "Failed to create coupon", variant: "destructive" });
    },
  });
}
