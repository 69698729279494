import { useQuery } from "@tanstack/react-query";
import { getSessions } from "../services/get-sessions";
import { Session } from "@/pages/sessions/types/session";

interface PaginatedSessions {
  sessions: Array<Session>;
  lastPage: number;
  nextPage: number | null;
  currentPage: number;
}
export default function useSessions(
  page: number = 0,
  search?: string,
  filter?: string,
  usage_id?: string
) {
  const { data, isLoading, error, isFetching, isSuccess } =
    useQuery<PaginatedSessions>({
      queryKey: [
        "sessions",
        {
          page,
          search,
          filter,
          usage_id,
        },
      ],
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 30, // 30 minutes
      queryFn: () => getSessions({ page, search, filter, usage_id }),
    });

  return { data, isLoading, error, isFetching, isSuccess };
}
