import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateBundle } from "../services/update-bundle";
import { UpdateBundleRequest } from "@/pages/bundles/types/bundle";

export function useUpdateBundle() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, data }: { id: string; data: UpdateBundleRequest }) =>
      updateBundle(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["bundles"] });
    },
  });
}
