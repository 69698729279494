import axiosInstance from "@/api/axios-instance";

export async function cancelSession(source_id: string, reason?: string) {
  const response = await axiosInstance.post(`/sessions/cancel`, {
    source_id,
    reason,
  });

  if (!response.status.toString().startsWith("2")) {
    throw new Error("Failed to cancel session");
  }

  return response.data;
}
