import axiosInstance from "@/api/axios-instance";
import { CouponFormData } from "../schema/coupon-schema";
import { Coupon } from "@/pages/coupons/types/coupon";

export async function createCoupon(data: CouponFormData) {
  const response = await axiosInstance.post<Coupon>("/coupon", data);
  
  if (!response.status.toString().startsWith("2")) {
    throw new Error("Failed to create coupon");
  }
  
  return response.data;
}